<template>
  <div class="flex gap-4 min-h-[56px] border-b border-gray-200 dark:border-gray-800 px-4 py-2.5 sticky top-0 bg-white/90 backdrop-blur-lg dark:bg-gray-900 z-20" @click="scrollTop">
    <UButton v-if="back" icon="i-heroicons-chevron-left" variant="link" :padded="false" @click.stop="$router.back()"/>
    <div class="flex flex-col justify-center self-stretch">
      <h1 v-if="title" class="font-bold text-xl leading-none align-middle">{{ title }}</h1>
      <p v-if="description" class="text-sm text-gray-500 leading-none align-middle line-clamp-1" v-html="description" />
    </div>
    <div class="ml-auto" @click.stop>
      <slot name="button" />
    </div>
  </div>
</template>

<script setup lang="ts">

const props = defineProps<{
  title?: string;
  description?: string;
  back?: boolean;
}>();

const scrollTop = () => {
  useScroll(window, { behavior: 'smooth' }).y.value = 0;
}
</script>